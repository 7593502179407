import { useContext, useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AuthContext } from './context/auth-context'
import { SettingsContext } from './context/settings-context'
import { MainRoutes } from './routes/main-routes'
import { UnauthenticatedUserRoutes } from './routes/unauthenticated-user-routes'
import { updateThemeScheme } from './utils/theme-settings'
import './assets/scss/hope-ui.scss'
import './assets/scss/dark.scss'

const router = (user) => createBrowserRouter(
    user ?
        MainRoutes :
        UnauthenticatedUserRoutes
)

function App() {
    const { user } = useContext(AuthContext)
    const { theme } = useContext(SettingsContext)

    useEffect(() => {
        updateThemeScheme(theme)
    }, [theme])

    return (
        <RouterProvider router={router(user)} />
    )
}

export default App
