import React, {memo, Fragment, useContext} from 'react'
import LogoDark from '../../assets/images/logo-dark.png'
import LogoLight from '../../assets/images/logo-light.png'
import { SettingsContext } from '../../context/settings-context'

const Logo = memo((props) => {
    const { theme } = useContext(SettingsContext)

    return (
        <Fragment>
            <div className="logo-main">
                <div className="logo-normal">
                   <img src={theme === 'dark' ? LogoDark : LogoLight} style={{height: '30px'}}/>
                </div>
            </div>
        </Fragment>
    );
});

Logo.displayName = 'Logo'
export default Logo;
