import Dashboard from '../pages/Dashboard'
import PageNotFound from '../pages/errors/PageNotFound'
import AppLayout from '../layouts/AppLayout'

export const MainRoutes =  [
    {
        path: '/',
        element: <AppLayout />,
        children: [
            {
                path: 'profile',
                element: <Dashboard />
            },
            {
                path: 'users',
                element: <Dashboard />
            },
            {
                path: 'campaigns',
                element: <Dashboard />
            },
            {
                path: 'social-feed',
                element: <Dashboard />
            },
            {
                path: 'reports',
                element: <Dashboard />
            },
            {
                path: 'favourite-feed',
                element: <Dashboard />
            }
        ]
    },
    {
        path: '*',
        element: <PageNotFound />
    }
]