import React, { memo, Fragment } from 'react'
import { Link } from 'react-router-dom'

const Footer = memo(() => {
    return (
        <Fragment>
            <footer className="footer default">
                <div className="footer-body">
                    <div className="left-panel"></div>
                    <div className="right-panel">
                        {new Date().getFullYear()} © Turba Media
                    </div>
                </div>
            </footer>
        </Fragment>
    )
})

Footer.displayName='Footer'
export default Footer
