import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import client from './utils/apollo-client'
import { ApolloProvider } from '@apollo/client'
import { AuthProvider } from './context/auth-context'
import { SettingsProvider } from './context/settings-context'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <SettingsProvider>
        <AuthProvider>
            <ApolloProvider client={client}>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </ApolloProvider>
        </AuthProvider>
    </SettingsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
